import React, { useState } from 'react';
import { BigNumber, ethers } from 'ethers';
import { useWeb3React } from '@web3-react/core';
import { GlobalStyle, Modal, Title, Input, BottomSection, ActionButton, CloseButton } from '../styles/GlobalModalStyles';
import ReactDOM from 'react-dom';
import { getContract } from '../utils/contract';
import { toast } from 'react-toastify';
import { handleError } from '../utils/errorHandler';
import { parseStringToBigNumber } from '../utils/formatter';

interface WithdrawModalProps {
  isOpen: boolean;
  onClose: () => void;
  balance: BigNumber;
}

interface WithdrawParams {
  amount: BigNumber;
}

const WithdrawModal: React.FC<WithdrawModalProps> = ({ isOpen, onClose, balance }) => {
  const { account, library } = useWeb3React();
  const [amount, setAmount] = useState('');

  const handleWithdraw = async () => {
    if (!account || !library) {
      toast.error("Carteira não conectada!");
      return;
    }

    const contract = getContract(library);

    try {

      if (amount === '') {
        toast.error("Quantidade inválida!");
        return;
      }

      const withdrawAmount = parseStringToBigNumber(amount);
      console.log("withdrawAmount", withdrawAmount.toString());

      if (balance.lt(withdrawAmount)) {
        toast.error("Saldo insuficiente para saque!");
        return;
      }

      const withdrawParams: WithdrawParams = { amount: withdrawAmount };

      const tx = await contract.multicall([contract.interface.encodeFunctionData('withdraw', [withdrawParams])]);
      const receipt = await tx.wait();
      if (receipt.status === 1) {
        toast.success("Pedido de saque enviado com sucesso!");
        setAmount('');
        onClose();
      } else {
        toast.error("A transação falhou!");
      }
    } catch (error: any) {
      const rejectedRequest = handleError(error, "User rejected the request.", "Transação cancelada pelo usuário!");
      toast.error(rejectedRequest || "Ocorreu um erro!");
    }
  };

  if (!isOpen) return null;

  const handleInputChange = (inputValue: string) => {
    inputValue = inputValue.replace(/\./g, ',');

    if (inputValue === ',' || inputValue === '.') {
      inputValue = '0,';
    }

    inputValue = inputValue.replace(/[^0-9,]/g, '');

    const parts = inputValue.split(',');
    if (parts.length > 2) {
      inputValue = parts[0] + ',' + parts.slice(1).join('');
    }

    if (inputValue.startsWith('0') && inputValue.length > 1 && !inputValue.startsWith('0,')) {
      inputValue = inputValue.replace(/^0+/, '');
    }

    if (inputValue === '') {
      inputValue = '0';
    }

    setAmount(inputValue);
    console.log("handleInputChange", inputValue);
  };

  return ReactDOM.createPortal(
    <>
      <GlobalStyle />
      <Modal $minHeight="200px">
        <Title>Sacar USDC</Title>
        <Input
          type="text"
          placeholder="Quantidade"
          value={amount}
          onChange={e => handleInputChange(e.target.value)}
        />
        <BottomSection>
          <ActionButton color="#3C99DC" onClick={handleWithdraw}>ENVIAR</ActionButton>
          <CloseButton color="#FF6347" onClick={() => { onClose(); setAmount(''); }}>FECHAR</CloseButton>
        </BottomSection>
      </Modal>
    </>,
    document.body
  );
};

export default WithdrawModal;
