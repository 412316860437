import React from 'react';
import { BigNumber } from 'ethers';
import { useWeb3React } from '@web3-react/core';
import { GlobalStyle, Modal, Title, BottomSection, ActionButton, CloseButton, ListItem, List } from '../styles/GlobalModalStyles';
import ReactDOM from 'react-dom';
import { getContract } from '../utils/contract';
import { updateOfferOnDB } from '../hooks/firebaseConfig';
import { toast } from 'react-toastify';
import { handleError } from '../utils/errorHandler';

interface Trade {
    tradeId: BigNumber;
    sellTrade: boolean;
}

interface CancelModalProps {
    trade: Trade;
    onClose: () => void;
}

interface CancelTradeParams {
    tradeId: string;
}

const CancelModal: React.FC<CancelModalProps> = ({ trade, onClose }) => {

    const { account, library } = useWeb3React();

    const handleCancel = async () => {
        if (!account || !library) {
            toast.error("Carteira não conectada!");
            return;
        }

        const contract = getContract(library);

        const tradeId = trade.tradeId.toString();

        const cancelTradeParams: CancelTradeParams = { tradeId: tradeId };

        try {
            const tx = await contract.multicall([contract.interface.encodeFunctionData('cancelTrade', [cancelTradeParams])]);
            const receipt = await tx.wait();
            if (receipt.status === 1) {
                toast.success("Cancelar oferta enviado com sucesso!");
                updateOfferOnDB(tradeId, null);
                onClose();
            } else {
                toast.error("A transação falhou!");
            }
        } catch (error: any) {
            const rejectedRequest = handleError(error, "User rejected the request.", "Transação cancelada pelo usuário!");
            const tradeAlreadyAccepted = handleError(error, "Trade already accepted", "Esta oferta foi aceita!");
            toast.error(rejectedRequest || tradeAlreadyAccepted || "Ocorreu um erro!");
        }
    };

    return ReactDOM.createPortal(
        <>
            <GlobalStyle />
            <Modal>
                <Title>Cancelar Oferta</Title>
                <List>
                    <ListItem>
                        Deseja cancelar a oferta de {trade.sellTrade ? 'venda' : 'compra'} <strong>ID {trade.tradeId.toString()}</strong>?
                    </ListItem>
                </List>
                <BottomSection>
                    <ActionButton color="#FF6347" onClick={handleCancel}>ENVIAR</ActionButton>
                    <CloseButton color="#3C99DC" onClick={() => { updateOfferOnDB(trade.tradeId.toString(), true); onClose() }}>FECHAR</CloseButton>
                </BottomSection>
            </Modal>
        </>,
        document.body
    );
};

export default CancelModal;
