import { ethers } from 'ethers';
import ContractABI from '../abis/ContractABI.json';

const usdcABI = [
  // ABI fragment for USDC
  "function allowance(address owner, address spender) external view returns (uint256)",
  "function approve(address spender, uint256 amount) external returns (bool)",
  "function balanceOf(address account) external view returns (uint256)"
];

export const getContract = (library: ethers.providers.Web3Provider) => {
  const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS as string;
  return new ethers.Contract(contractAddress, ContractABI, library.getSigner());
};

export const getUsdcContract = (library: ethers.providers.Web3Provider) => {
  const usdcAddress = process.env.REACT_APP_USDC_CONTRACT_ADDRESS as string;
  return new ethers.Contract(usdcAddress, usdcABI, library.getSigner());
};
