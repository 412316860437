import React, { useState } from 'react';
import { BigNumber, ethers } from 'ethers';
import { GlobalStyle, Modal, Title, Input, BottomSection, ActionButton, CloseButton } from '../styles/GlobalModalStyles';
import ReactDOM from 'react-dom';
import { useWeb3React } from '@web3-react/core';
import { getContract, getUsdcContract } from '../utils/contract';
import { toast } from 'react-toastify';
import { handleError } from '../utils/errorHandler';

interface DepositModalProps {
    isOpen: boolean;
    onClose: () => void;
}

interface DepositParams {
    amount: BigNumber;
}

const DepositModal: React.FC<DepositModalProps> = ({ isOpen, onClose }) => {
    const { account, library } = useWeb3React();
    const [amount, setAmount] = useState('');

    const handleDeposit = async () => {
        if (!account || !library) {
            toast.error("Carteira não conectada!");
            return;
        }

        const contract = getContract(library);
        const usdcContract = getUsdcContract(library);

        try {

            if (amount === '' || amount === '0') {
                toast.error("Quantidade inválida!");
                return;
            }

            const depositAmount = ethers.utils.parseUnits(amount, 6);

            const balance = await usdcContract.balanceOf(account);
            if (balance.lt(depositAmount)) {
                toast.error("Saldo insuficiente para depósito!");
                return;
            }

            const allowance = await usdcContract.allowance(account, contract.address);

            if (allowance.lt(depositAmount)) {
                const txApprove = await usdcContract.approve(contract.address, depositAmount);
                await txApprove.wait();
                toast.success("Approvado com successo!");
            }

            const depositParams: DepositParams = { amount: depositAmount };

            const tx = await contract.multicall([contract.interface.encodeFunctionData('deposit', [depositParams])]);
            const receipt = await tx.wait();
            if (receipt.status === 1) {
                toast.success("Pedido de depósito enviado com sucesso!");
                setAmount('');
                onClose();
            } else {
                toast.error("A transação falhou!");
            }
        } catch (error: any) {
            const rejectedRequest = handleError(error, "User rejected the request.", "Transação cancelada pelo usuário!");
            toast.error(rejectedRequest || "Ocorreu um erro!");
        }
    };

    if (!isOpen) return null;

    return ReactDOM.createPortal(
        <>
            <GlobalStyle />
            <Modal $minHeight="200px">
                <Title>Depositar USDC</Title>
                <Input
                    pattern="[0-9]*"
                    type="text"
                    placeholder="Quantidade"
                    value={amount}
                    onChange={e => {
                        let newAmount = e.target.value.replace(/\D/g, '');
                        newAmount = newAmount.replace(/^0+/, '');
                        if (newAmount === '') newAmount = '0';
                        setAmount(newAmount);
                    }}
                />
                <BottomSection>
                    <ActionButton color="#3C99DC" onClick={handleDeposit}>ENVIAR</ActionButton>
                    <CloseButton color="#FF6347" onClick={() => { onClose(); setAmount(''); }}>FECHAR</CloseButton>
                </BottomSection>
            </Modal>
        </>,
        document.body
    );
};

export default DepositModal;
