import { toast } from 'react-toastify';
import { ethers, BigNumber } from 'ethers';
import { parseBigNumberToString } from './formatter';

interface NotifyData {
    amount?: BigNumber;
    price?: BigNumber;
    fiatCurrency?: string;
    tradeType?: 'venda' | 'compra';
    invertTradeType?: boolean;
    tradeId?: BigNumber;
    status?: boolean;
}

interface User {
    username: string;
    user: string;
}

export const notifyUser = (user: User, event: string, data?: NotifyData) => {
    const username = user.username !== '' ? user.username : user.user;

    let message = '';
    switch (event) {
        case 'whitelistedUser':
            message = `Bem vindo @${username}`;
            break;
        case 'userLimitsUpdated':
            message = `Os limites de negociação para @${username} foram atualizados!`;
            break;
        case 'userAccessUpdated':
            message = `Seu acesso @${username} foi ${data?.status ? 'liberado!' : 'revogado!'}`;
            break;
        case 'deposit':
            message = `Depositado ${parseBigNumberToString(data?.amount ?? BigNumber.from(0))} USDC para @${username}`;
            break;

        case 'withdraw':
            message = `Retirado ${parseBigNumberToString(data?.amount ?? BigNumber.from(0))} USDC de @${username}`;
            break;

        case 'tradeInitiated':
            message = `Oferta de ${data?.tradeType} iniciada!${data?.tradeType === 'venda' ? ' Vender' : ' Comprar'} ${parseBigNumberToString(data?.amount ?? BigNumber.from(0))} USDC por ${(parseFloat(ethers.utils.formatUnits(data?.amount ?? 0, 6)) * parseFloat(ethers.utils.formatUnits(data?.price ?? 0, 2))).toFixed(2)} ${data?.fiatCurrency}`;
            break;

        case 'tradeAccepted':
            message = `Sua oferta de ${data?.tradeType} foi aceita!${data?.tradeType === 'venda' ? ' Vender' : ' Comprar'} ${parseBigNumberToString(data?.amount ?? BigNumber.from(0))} USDC por ${(parseFloat(ethers.utils.formatUnits(data?.amount ?? 0, 6)) * parseFloat(ethers.utils.formatUnits(data?.price ?? 0, 2))).toFixed(2)} ${data?.fiatCurrency}`;
            break;

        case 'tradeCancelled':
            message = `Sua oferta de ${data?.tradeType} foi cancelada!`;
            break;

        case 'tradeCompleted':
            message = `A oferta de ${data?.tradeType} foi concluída! Voce${data?.invertTradeType ? (data?.tradeType === 'venda' ? ' comprou' : ' vendeu') : (data?.tradeType === 'venda' ? ' vendeu' : ' comprou')} ${parseBigNumberToString(data?.amount ?? BigNumber.from(0))} USDC por ${(parseFloat(ethers.utils.formatUnits(data?.amount ?? 0, 6)) * parseFloat(ethers.utils.formatUnits(data?.price ?? 0, 2))).toFixed(2)} ${data?.fiatCurrency}`;
            break;

        case 'disputeWon':
            message = `@${username} você venceu a disputa da oferta de ${data?.tradeType} ID ${data?.tradeId?.toString()}!`;
            break;

        case 'disputeLost':
            message = `@${username} você perdeu a disputa da oferta de ${data?.tradeType} ID ${data?.tradeId?.toString()}. Seu SCORE foi reduzido!`;
            break;

        case 'disputeNobody':
            message = `@${username} não houve disputa para a oferta de ${data?.tradeType} ID ${data?.tradeId?.toString()}!`;
            break;

        default:
            message = `Notificação para @${username}.`; // modificar
    }

    toast.success(message);
};
