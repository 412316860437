import React from "react";
import { BigNumber } from "ethers";
import { useWeb3React } from "@web3-react/core";
import { GlobalStyle, Modal, Title, BottomSection, ActionButton, CloseButton, List, ListItem } from '../styles/GlobalModalStyles';
import ReactDOM from 'react-dom';
import { getContract } from "../utils/contract";
import { toast } from "react-toastify";
import { handleError } from "../utils/errorHandler";

interface User {
  user: string;
  username: string;
}

interface Trade {
  user1: User;
  user2: User;
  tradeId: BigNumber;
  sellTrade: boolean;
}

interface ConfirmModalProps {
  trade: Trade;
  onClose: () => void;
}

interface ConfirmReceiptParams {
  tradeId: string;
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({ trade, onClose }) => {

  const { account, library } = useWeb3React();

  const handleConfirm = async () => {
    if (!account || !library) {
      toast.error("Carteira não conectada!");
      return;
    }

    const contract = getContract(library);

    const confirmReceiptParams: ConfirmReceiptParams = { tradeId: trade.tradeId.toString() };

    try {
      const tx = await contract.multicall([contract.interface.encodeFunctionData('confirmReceipt', [confirmReceiptParams])]);
      const receipt = await tx.wait();
      if (receipt.status === 1) {
        onClose();
      } else {
        toast.error("A transação falhou!");
      }
    } catch (error: any) {
      const rejectedRequest = handleError(error, "User rejected the request.", "Transação cancelada pelo usuário!");
      toast.error(rejectedRequest || "Ocorreu um erro!");
    }
  };

  return ReactDOM.createPortal(
    <>
      <GlobalStyle />
      <Modal>
        <Title>Confirmar Oferta</Title>
        <List>
          <ListItem>
            Deseja confirmar a oferta de {trade.sellTrade ?'venda' : 'compra'} <strong>ID {trade.tradeId.toString()}</strong>
          </ListItem>
          <ListItem>
          {trade.sellTrade ? 'aceita' : 'feita'} pelo usuario: <strong>@{trade.sellTrade ? trade.user2.username !== '' ? trade.user2.username : trade.user2.user : trade.user1.username !== '' ? trade.user1.username : trade.user1.user}</strong>
          </ListItem>
        </List>
        <BottomSection>
          <ActionButton color="#3C99DC" onClick={handleConfirm}>ENVIAR</ActionButton>
          <CloseButton color="#FF6347" onClick={onClose}>FECHAR</CloseButton>
        </BottomSection>
      </Modal>
    </>,
    document.body
  );
}

export default ConfirmModal;
