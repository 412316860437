import React, { useState } from 'react';
import { BigNumber, ethers } from 'ethers';
import { GlobalStyle, Modal, Title, UserButton, ActionButton, CloseButton, BottomSection, List, ListItem } from '../styles/GlobalModalStyles';
import ReactDOM from 'react-dom';
import { useWeb3React } from '@web3-react/core';
import { getContract } from '../utils/contract';
import { toast } from 'react-toastify';
import { handleError } from '../utils/errorHandler';

interface User {
  user: string;
  username: string;
}

interface Trade {
  user1: User;
  user2: User;
  tradeId: BigNumber;
  sellTrade: boolean;
  acceptedTimestamp: BigNumber;
}

interface JudgeModalProps {
  trade: Trade;
  onClose: () => void;
}

interface ResolveDisputeParams {
  winner: string;
  tradeId: string;
}

const JudgeModal: React.FC<JudgeModalProps> = ({ trade, onClose }) => {
  const [winner, setWinner] = useState<string>('');

  const { account, library } = useWeb3React();

  const handleUser00Winner = () => {
    setWinner(ethers.constants.AddressZero);
    toast.warn(`Não haverá vencedor nessa disputa!`);
  }

  const handleUser01Winner = () => {
    setWinner(trade.user1.user);
    toast.warn(`Usuario vencedor será ${trade.user1.username}`);
  }

  const handleUser02Winner = () => {
    setWinner(trade.user2.user);
    toast.warn(`Usuario vencedor será ${trade.user2.username}`);
  }

  const handleJudge = async () => {
    if (!account || !library) {
      toast.error("Carteira não conectada!");
      return;
    }

    const contract = getContract(library);

    const resolveDisputeParams: ResolveDisputeParams = {
      winner: winner,
      tradeId: trade.tradeId.toString()
    };

    if (winner !== '') {
      try {
        const tx = await contract.multicall([contract.interface.encodeFunctionData('resolveDispute', [resolveDisputeParams])]);
        const receipt = await tx.wait();
        if (receipt.status === 1) {
          toast.success("Troca julgada!");
          onClose();
        } else {
          toast.error("A transação falhou!");
        }
      } catch (error: any) {
        const rejectedRequest = handleError(error, "User rejected the request.", "Transação cancelada pelo usuário!");
        toast.error(rejectedRequest || "Ocorreu um erro!");
      }
    } else {
      toast.error("Selecione o vencedor!");
    }
  };

  return ReactDOM.createPortal(
    <>
      <GlobalStyle />
      <Modal $minHeight="390px">
        <Title>Julgar Trade</Title>
        <List>
          <ListItem>
            <strong>Comprador</strong>: {trade.sellTrade ? trade.user2.username : trade.user1.username}
          </ListItem>
          <ListItem>
            <strong>Vendedor</strong>: {trade.sellTrade ? trade.user1.username : trade.user2.username}
          </ListItem>
          <ListItem>
            <strong>Data</strong>: {new Date(trade.acceptedTimestamp.toNumber() * 1000).toLocaleString()}
          </ListItem>
        </List>
        <UserButton color="#FF9900" onClick={handleUser01Winner}>{trade.user1.username}</UserButton>
        <UserButton color="#FF9900" onClick={handleUser02Winner}>{trade.user2.username}</UserButton>
        <UserButton color="#FF9900" onClick={handleUser00Winner}>NINGUEM</UserButton>
        <BottomSection>
          <ActionButton color="#3C99DC" onClick={handleJudge}>ENVIAR</ActionButton>
          <CloseButton color="#FF6347" onClick={onClose}>FECHAR</CloseButton>
        </BottomSection>
      </Modal>
    </>,
    document.body
  );
}

export default JudgeModal;
