import styled, { createGlobalStyle, css, keyframes } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Roboto Mono', monospace;
  }
`;

interface ButtonProps {
  color?: string;
  left?: boolean;
  right?: boolean;
  $variation?: 'normal' | 'inverted';
  hoverColor?: string;
  $blinking?: string;
  sellTrade?: boolean;
  $hover?: string;
}

export const trapezoidStyles = css<ButtonProps>`
  display: inline-block;
  padding: 8px 20px;
  margin: 10px;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  transition: transform 0.4s ease;
  clip-path: ${(props) => (props.$variation === 'inverted' ? 'polygon(0% 0%, 85% 0%, 100% 100%, 15% 100%)' : 'polygon(15% 0%, 100% 0%, 85% 100%, 0% 100%)')};
  border: 2px solid #000;
  min-width: 125px;
  overflow: hidden;

  &:hover {
    background-color: #3c99dc;
  }
`;

const trapezoidTradeListStyles = css<ButtonProps>`
  padding: 8px 20px;
  margin: 10px;
  background-color: ${(props) => props.color};
  position: relative;
  transition: transform 0.4s ease;
  clip-path: ${(props) =>
    props.$variation === 'inverted'
      ? 'polygon(0% 0%, 100% 0%, 85% 100%, 0% 100%)'
      : 'polygon(0% 0%, 100% 0%, 100% 100%, 15% 100%)'};
  border: 2px solid #000;
  overflow: hidden;

  &:hover {
    background-color: ${(props) => (props.$hover === 'yes' ? props.color : '#3c99dc')};
  }
`;

export const Button = styled.button<ButtonProps>`
  ${trapezoidStyles};
  font-family: 'Roboto Mono', monospace;
  font-size: 18px;
  border: none;
  cursor: pointer;
  position: relative;

  &:hover {
    background-color: #3C99DC;
  }
`;

export const Input = styled.input`
  display: block;
  padding: 10px;
  margin: 10px auto;
  width: calc(100% - 40px);
  border: 2px solid #000;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s;
  font-family: 'Roboto Mono', monospace;

  &:focus {
    border-color: #3C99DC;
  }
`;

export const Select = styled.select`
  padding: 10px;
  margin: 10px auto;
  width: calc(100% - 40px);
  border: 2px solid #000;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s;
  font-family: 'Roboto Mono', monospace;

  &:focus {
    border-color: #3C99DC;
  }
`;

export const Modal = styled.div<{ width?: string; minWidth?: string; height?: string; $minHeight?: string }>`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: ${({ width }) => width};
  min-width: ${({ minWidth }) => minWidth};
  height: ${({ height }) => height};
  min-height: ${({ $minHeight }) => $minHeight};
  max-width: 600px;
  padding: 20px;
  border: 1px solid black;
  background-color: #f0f0f0;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Title = styled.h2`
  margin: 0;
  font-family: 'Roboto Mono', monospace;
  text-align: center;
  line-height: 1.5;
  border-bottom: 1px solid black;
  padding-bottom: 10px;
`;

export const Paragraph = styled.p`
  margin-bottom: 20px;
  line-height: 1.6;
  font-family: 'Roboto Mono', monospace;
  word-wrap: break-word;
`;

export const List = styled.ul`
  list-style-type: none;
  padding-left: 0;
  margin: 10px 0;
`;

export const ListItem = styled.li`
  margin-bottom: 5px;
`;

export const BottomSection = styled.div`
  border-top: 1px solid black;
  padding-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

export const ActionButton = styled(Button)`
  background-color: #0FB10F;
  margin: 5px;
  flex: none;
`;

export const CloseButton = styled(Button)`
  background-color: #D22D2D;
  margin: 5px;
  flex: none;
`;

export const TotalText = styled.p`
  font-size: 14px;
  cursor: default;
`;

export const UserButton = styled(Button)`
background-color: #FF9900;
padding: 10px 50px;
display: block;
margin: 10px;
`;

export const TradeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  border: none;
  background-color: #000;
  margin-top: 2%;  
`;

export const TradeListStyled = styled.div`
  width: 48%;
  padding: 10px;
  border: none;
  margin: 10px;
  max-height: calc(100vh - 500px);
  overflow-y: auto;
  overflow-x: hidden;

  -ms-overflow-style: none;
  scrollbar-width: none;

  scroll-behavior: smooth;

  @media (max-width: 1366px) {
    max-height: calc(100vh - 400px);
  }
`;

const dimmer = keyframes`
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
`;

const blinkAnimation = css`
  animation: ${dimmer} 1s infinite;
`;

const slideInLeft = keyframes`
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

const slideInRight = keyframes`
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

const fadeOutFall = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(100%);
  }
`;

export const TradeItem = styled.button<ButtonProps>`
  ${trapezoidTradeListStyles};
  margin: 10px auto;
  display: flex;
  cursor: pointer;
  justify-content: ${(props) => (props.$variation === 'inverted' ? 'left' : 'right')};
  font-weight: bold;
  text-transform: uppercase;
  width: 92%;
  background-color: ${(props) => props.color};
  border: none;
  animation: ${(props) =>
    props.$variation === 'inverted'
      ? css`${slideInRight} 0.5s ease-out`
      : css`${slideInLeft} 0.5s ease-out`};
  
  &.fade-out {
    animation: ${fadeOutFall} 0.5s forwards;
  }

  p {
    margin: 0;
    font-size: 23px;
    margin: 0px 23px;
    font-family: 'Roboto Mono', monospace;
    white-space: nowrap;
  }

  p:last-child {
    margin-bottom: 0;
  }

  @media (max-width: 1366px) {
    p {
      margin: 0px 20px;
      font-size: 20px;
    }
  }

  ${(props) =>
    props.$variation === 'normal'
      ? `
    p:nth-child(1) { order: 4; }
    p:nth-child(2) { order: 3; }
    p:nth-child(3) { order: 2; }
    p:nth-child(4) { order: 1; }
  `
      : `
    p:nth-child(1) { order: 1; }
    p:nth-child(2) { order: 2; }
    p:nth-child(3) { order: 3; }
    p:nth-child(4) { order: 4; }
  `}

  ${(props) => props.$blinking === 'yes' && blinkAnimation}
`;
