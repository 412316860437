import React from 'react';
import { BigNumber, ethers } from 'ethers';
import { useWeb3React } from '@web3-react/core';
import { GlobalStyle, Modal, Title, List, ListItem, BottomSection, ActionButton, CloseButton } from '../styles/GlobalModalStyles';
import ReactDOM from 'react-dom';
import { getContract } from '../utils/contract';
import { updateOfferOnDB } from '../hooks/firebaseConfig';
import { toast } from 'react-toastify';
import { handleError } from '../utils/errorHandler';
import { parseBigNumberToString } from '../utils/formatter';

interface Limits {
    collateralPercent: number;
    feePercent: number;
}

interface User {
    user: string;
    username: string;
    limits: Limits;
}

interface Trade {
    user1: User;
    user2: User;
    tradeId: BigNumber;
    sellTrade: boolean;
    amount: BigNumber;
    price: BigNumber;
    fiatCurrency: string;
    acceptedTimestamp: BigNumber;
}

interface AcceptModalProps {
    onClose: () => void;
    trade: Trade;
    user: User;
}

interface AcceptTradeParams {
    tradeId: string;
}

const AcceptModal: React.FC<AcceptModalProps> = ({ onClose, trade, user }) => {

    const { account, library } = useWeb3React();

    const handleAccept = async () => {
        if (!account || !library) {
            toast.error("Carteira não conectada!");
            return;
        }

        const contract = getContract(library);

        const tradeId = trade.tradeId.toString();

        const acceptTradeParams: AcceptTradeParams = { tradeId: tradeId };

        try {
            const tx = await contract.multicall([contract.interface.encodeFunctionData('acceptTrade', [acceptTradeParams])]);
            const receipt = await tx.wait();
            if (receipt.status === 1) {
                toast.success("Oferta aceita com sucesso!");
                updateOfferOnDB(tradeId, null);
                onClose();
            } else {
                toast.error("A transação falhou!");
            }
        } catch (error: any) {
            const whitelistedUser = handleError(error, "Not whitelisted user", "Usuário não tem permissão para aceitar ofertas!");
            const rejectedRequest = handleError(error, "User rejected the request.", "Transação cancelada pelo usuário!");
            const tradeAlreadyAccepted = handleError(error, "Trade already accepted", "Esta oferta foi aceita!");
            toast.error(whitelistedUser || tradeAlreadyAccepted || rejectedRequest || "Ocorreu um erro!");
        }
    };

    const collateralAmount = trade.amount.mul(user.limits.collateralPercent).div(10000);
    const feeAmount = trade.amount.mul(user.limits.feePercent).div(10000);

    return ReactDOM.createPortal(
        <>
            <GlobalStyle />
            <Modal minWidth="675px" $minHeight="306px">
                <Title>Aceitar Oferta de {trade.sellTrade ? 'Venda' : 'Compra'}</Title>
                <br />
                <strong>ESTA AÇÃO NÃO PODE SER DESFEITA!</strong>
                <br />
                Após aceitar esta oferta, serão debitados do seu saldo:
                <br />
                <List>
                    <ListItem>
                        <strong>
                            {!trade.sellTrade ? `${parseBigNumberToString(trade.amount)} USDC` : ''}
                        </strong>
                        {!trade.sellTrade ? `(valor negociado)` : ''}
                    </ListItem>
                    <ListItem>
                        <strong>
                            {user.limits.feePercent > 0 ? `${parseBigNumberToString(feeAmount)} USDC` : ''}
                        </strong>
                        {user.limits.feePercent > 0 ? (
                            <>
                                (taxa de <strong>{parseFloat(ethers.utils.formatUnits(user.limits.feePercent, 2)).toFixed(0)}%</strong> do valor negociado)
                            </>
                        ) : ''}
                    </ListItem>
                    <ListItem>
                        <strong>{parseBigNumberToString(collateralAmount)} USDC</strong>
                        (colateral de <strong>{parseFloat(ethers.utils.formatUnits(user.limits.collateralPercent, 2)).toFixed(0)}%</strong> do valor negociado)
                    </ListItem>
                </List>
                <p>
                    O colateral será devolvido automaticamente após a conclusão da oferta. Para concluir a oferta, entre em contato com o usuário:
                    <strong>@{trade.user1.username !== '' ? trade.user1.username : trade.user1.user}</strong>, oferta <strong>ID {trade.tradeId.toString()}</strong>.
                </p>
                <BottomSection>
                    <ActionButton color="#3C99DC" onClick={handleAccept}>ENVIAR</ActionButton>
                    <CloseButton color="#FF6347" onClick={() => { updateOfferOnDB(trade.tradeId.toString(), true); onClose() }}>FECHAR</CloseButton>
                </BottomSection>
            </Modal>
        </>,
        document.body
    );
};

export default AcceptModal;
