import { useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { InjectedConnector } from "@web3-react/injected-connector";

const NETWORK_ID = parseInt(process.env.REACT_APP_NETWORK_ID as string);

const NETWORK_PARAMS = {
  chainId: `0x${NETWORK_ID.toString(16)}`,
  chainName: 'Arbitrum',
  rpcUrls: ['https://arb1.arbitrum.io/rpc'],
  nativeCurrency: { name: 'Ethereum', symbol: 'ETH', decimals: 18 },
  blockExplorerUrls: ['https://arbiscan.io/'],
};

const injected = new InjectedConnector({
  supportedChainIds: [NETWORK_ID],
});

export const useWeb3 = () => {
  const { activate, active, account, library, chainId } = useWeb3React();
  const [isMetaMaskAvailable, setIsMetaMaskAvailable] = useState(false);

  // Função para verificar se o MetaMask está disponível
  const checkMetaMaskAvailability = async (retries = 5) => {
    for (let attempt = 0; attempt < retries; attempt++) {
      if (window.ethereum?.isMetaMask) {
        setIsMetaMaskAvailable(true);
        return true;
      }
      console.warn(`Tentativa ${attempt + 1}: MetaMask não detectado. Tentando novamente...`);
      await new Promise((resolve) => setTimeout(resolve, 500)); // Delay de 500ms
    }
    console.error("MetaMask não está instalado.");
    return false;
  };

  const addNetwork = async () => {
    if (!isMetaMaskAvailable) return;
    try {
      await window.ethereum?.request({
        method: 'wallet_addEthereumChain',
        params: [NETWORK_PARAMS],
      });
    } catch (error) {
      console.error("Erro ao adicionar a rede:", error);
    }
  };

  const switchToCorrectNetwork = async () => {
    if (!isMetaMaskAvailable) return;
    try {
      await window.ethereum?.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: NETWORK_PARAMS.chainId }],
      });
    } catch (error: any) {
      if (error.code === 4902 || error.code === -32603) {
        console.warn("Rede não encontrada. Tentando adicionar...");
        await addNetwork();
      } else {
        console.error("Erro ao trocar de rede:", error);
      }
    }
  };

  useEffect(() => {
    const handleChainChanged = async (newChainId: string) => {
      const parsedChainId = parseInt(newChainId, 16);
      if (parsedChainId !== NETWORK_ID) await switchToCorrectNetwork();
    };

    if (isMetaMaskAvailable) {
      window.ethereum?.on('chainChanged', handleChainChanged);
    }

    return () => {
      if (isMetaMaskAvailable) {
        window.ethereum?.removeListener('chainChanged', handleChainChanged);
      }
    };
  }, [isMetaMaskAvailable]);

  useEffect(() => {
    const initConnection = async () => {
      if (!isMetaMaskAvailable) return;
      try {
        await activate(injected);
        if (chainId !== NETWORK_ID) await switchToCorrectNetwork();
      } catch (err) {
        console.error("Erro ao ativar a conexão:", err);
      }
    };

    checkMetaMaskAvailability().then((available) => {
      if (available && !active) initConnection();
    });
  }, [activate, active, chainId, isMetaMaskAvailable]);

  return { account, active, library };
};
