import { BigNumber, ethers } from "ethers";

export function parseBigNumberToString(balance: BigNumber): string {
    const formatted = parseFloat(ethers.utils.formatUnits(balance, 6));

    let decimalPlaces = 0;

    if (formatted > 1) {
        decimalPlaces = 4;
    } else if (formatted > 0.000000) {
        decimalPlaces = Math.min(6, 6 - Math.floor(Math.log10(formatted)));
    }

    return parseFloat(formatted.toFixed(decimalPlaces)).toString();
}

export function parseStringToBigNumber(value: string): BigNumber {
    let standardizedValue = value.replace(/\./g, ',');
    const parts = standardizedValue.split(',');
    if (parts.length > 2) {
        standardizedValue = parts[0] + ',' + parts.slice(1).join('');
    }
    standardizedValue = standardizedValue.replace(',', '.');

    if (isNaN(parseFloat(standardizedValue))) {
        throw new Error("Valor inserido não é válido.");
    }

    const valueInWei = parseFloat(standardizedValue) * Math.pow(10, 6);
    return BigNumber.from(Math.floor(valueInWei).toString());
}
