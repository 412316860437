import React, { useState } from 'react';
import { BigNumber, ethers } from 'ethers';
import { useWeb3React } from '@web3-react/core';
import { GlobalStyle, Modal, Title, Select, Input, TotalText, BottomSection, ActionButton, CloseButton } from '../styles/GlobalModalStyles';
import ReactDOM from 'react-dom';
import { calculateAmounts } from '../utils/tradeCalculations';
import { getContract } from '../utils/contract';
import { toast } from 'react-toastify';

import { handleError } from '../utils/errorHandler';
import { parseBigNumberToString } from '../utils/formatter';

interface Limits {
  opennedTradesLimit: number;
  maxTradeAmountLimit: BigNumber;
  collateralPercent: number;
  feePercent: number;
}

interface User {
  limits: Limits;
  opennedTradesCount: number;
}

interface TradeModalProps {
  isOpen: boolean;
  onClose: () => void;
  balance: BigNumber;
  user: User;
  sellTrade: boolean;
}

interface InitiateTradeParams {
  amount: BigNumber;
  price: BigNumber;
  sellTrade: boolean;
  fiatCurrency: string;
}

const TradeModal: React.FC<TradeModalProps> = ({ isOpen, onClose, balance, user, sellTrade }) => {
  const { account, library } = useWeb3React();

  const [amount, setAmount] = useState('');
  const [price, setPrice] = useState('');
  const [totalRequired, setTotalRequired] = useState<string>('0');
  const [fiatCurrency, setfiatCurrency] = useState('BRL');

  const resetFields = () => {
    setTotalRequired('');
    setAmount('');
    setPrice('');
  };

  const formatPrice = (value: string): string => {
    value = value.replace(/\D/g, '').padStart(3, '0');
    return `${value.slice(0, -2)},${value.slice(-2)}`.replace(/^0+(?![.,])/g, '');
  };

  const parsePrice = (value: string) => value.replace(',', '');

  const updateTotal = (amount: string) => {
    if (amount) {
      const { totalAmount } = calculateAmounts(amount, user.limits.collateralPercent, user.limits.feePercent, sellTrade);
      setTotalRequired(parseBigNumberToString(totalAmount));
    } else {
      setTotalRequired('0');
    }
  };

  const handleTrade = async () => {
    if (!account || !library) {
      toast.error("Carteira não conectada!");
      return;
    }

    if (amount === '' || price === '') {
      toast.error("Preencha todos os campos!");
      return;
    }

    const contract = getContract(library);

    const { tradeAmount, totalAmount } = calculateAmounts(amount, user.limits.collateralPercent, user.limits.feePercent, sellTrade);

    if (balance.lt(totalAmount)) {
      toast.error("Saldo insuficiente para abrir uma oferta!");
      return;
    }

    if (tradeAmount.gt(user.limits.maxTradeAmountLimit)) {
      toast.error(`Valor máximo permitido da oferta é de ${parseBigNumberToString(user.limits.maxTradeAmountLimit)} USDC`);
      return;
    }

    if (tradeAmount.lt(ethers.utils.parseUnits('10', 6))) {
      toast.error("O valor da oferta deve ser maior ou igual a 10 USDC");
      return;
    }

    if (!BigNumber.from(parsePrice(price)).gt(0)) {
      toast.error("O preço precisa ser maior que zero!");
      return;
    }

    const initiateTradeParams: InitiateTradeParams = {
      amount: tradeAmount,
      price: BigNumber.from(parsePrice(price)),
      sellTrade,
      fiatCurrency: fiatCurrency
    }

    //const data: Object[] = [];

    try {
      const tx = await contract.multicall([contract.interface.encodeFunctionData('initiateTrade', [initiateTradeParams])]);
      const receipt = await tx.wait();
      if (receipt.status === 1) {
        toast.success("Oferta enviada com sucesso!");
        resetFields();
        onClose();
      } else {
        toast.error("A transação falhou!");
      }
    } catch (error: any) {
      const limitReached = handleError(error, "Trade limit reached", "Seu limite de ofertas diária foi atingido!");
      const rejectedRequest = handleError(error, "User rejected the request.", "Transação cancelada pelo usuário!");
      toast.error(limitReached || rejectedRequest || "Ocorreu um erro!");
    }
  };

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <>
      <GlobalStyle />
      <Modal width="280px" $minHeight="330px">
        <Title>{sellTrade ? 'Vender USDC' : 'Comprar USDC'}</Title>
        <Input
          pattern="[0-9]*"
          type="text"
          placeholder="Quantidade"
          title={`Max: ${parseBigNumberToString(user.limits.maxTradeAmountLimit)} USDC`}
          value={amount}
          onChange={e => {
            let newAmount = e.target.value.replace(/\D/g, '');
            newAmount = newAmount.replace(/^0+/, '');
            if (newAmount === '') newAmount = '0';
            setAmount(newAmount);
            updateTotal(newAmount);
          }}
        />
        <Input
          type="text"
          placeholder="Preço"
          value={price}
          onChange={e => setPrice(formatPrice(e.target.value))}
        />
        <Select value={fiatCurrency} onChange={e => setfiatCurrency(e.target.value)}>
          <option value="BRL">Real (BRL)</option>
        </Select>
        <BottomSection>
          <ActionButton color="#3C99DC" onClick={handleTrade}>ENVIAR</ActionButton>
          <CloseButton color="#FF6347" onClick={() => { onClose(); resetFields(); }}>FECHAR</CloseButton>
        </BottomSection>
        {totalRequired > '0' && (
          <TotalText>Total necessário: {totalRequired} USDC</TotalText>
        )}
      </Modal>
    </>,
    document.body
  );
};

export default TradeModal;

/*
<option value="EUR">Euro (EUR)</option>
*/