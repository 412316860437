import { initializeApp } from "firebase/app";
import { getDatabase, ref, onValue, set } from "firebase/database";

const databaseUrl = process.env.REACT_APP_DATABASE_URL as string;

const app = initializeApp({ databaseURL: databaseUrl, });

const database = getDatabase(app);

interface Offer {
    [key: string]: boolean;
}

export const listenOffersFromDB = (callback: (data: Offer | null) => void) => {
    onValue(ref(database, 'offers'), (snapshot) => {
        const data: Offer | null = snapshot.val();
        callback(data);
    });
};

export const updateOfferOnDB = (tradeId: string, value: boolean | null) => {
    set(ref(database, 'offers/' + tradeId), value);
};
