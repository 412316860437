import { BigNumber, ethers } from 'ethers';

export const calculateAmounts = (
    amount: string,
    collateralPercentage: number,
    feePercentage: number,
    sellTrade: boolean
): { tradeAmount: BigNumber, totalAmount: BigNumber } => {
    const tradeAmount = ethers.utils.parseUnits(amount, 6);
    const collateralAmount = tradeAmount.mul(collateralPercentage).div(10000);
    const feeAmount = tradeAmount.mul(feePercentage).div(10000);
    const totalAmount = sellTrade
        ? tradeAmount.add(collateralAmount).add(feeAmount)
        : collateralAmount.add(feeAmount);

    return { tradeAmount, totalAmount };
};
